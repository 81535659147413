.footer {
  background: linear-gradient(135deg, #B22222 0%, #8B0000 100%); /* Gradient from bright red to dark red */
  color: #ffffff;
  padding: 50px 0;
  margin-top: 20px;
  font-family: 'Mukta', sans-serif; /* Modern font */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid */
  gap: 30px; /* Better spacing */
}

.footer-section {
  margin-bottom: 20px;
}

.footer-section h3 {
  font-size: 1.7rem; /* Larger font for headings */
  margin-bottom: 15px;
  color: #ffffff; /* White color for better contrast */
  text-transform: uppercase;
  letter-spacing: 1px; /* Letter-spacing for clarity */
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 12px;
}

.footer-section a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease, text-shadow 0.3s ease; /* Transition effect */
}

.footer-section a:hover {
  color: #FF4500; /* Bright orange-red color on hover */
  text-shadow: 0 0 10px rgba(255, 69, 0, 0.7); /* Glowing effect */
}

.footer-bottom {
  background-color: #7C0A02; /* Darker red shade for the bottom footer */
  padding: 20px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2); /* Subtle top border */
}

.footer-bottom-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.footer-bottom p {
  margin: 0;
  font-size: 1rem;
  color: #f1f1f1; /* Slightly lighter text for readability */
}

.social-icons {
  display: flex;
  align-items: center;
  gap: 15px;
}

.social-icons a {
  font-size: 1.7rem;
  color: #ffffff;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-icons a:hover {
  color: #FF4500; /* Bright orange-red color on hover */
  transform: scale(1.2); /* Slightly enlarge icon on hover */
}

/* Media queries for responsiveness */

@media screen and (max-width: 768px) {
  .footer-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust column width for smaller screens */
  }
}

@media screen and (max-width: 576px) {
  .footer-section h3 {
    font-size: 1.4rem; /* Decrease font size for smaller screens */
  }

  .social-icons a {
    font-size: 1.4rem; /* Decrease social icon size for smaller screens */
  }

  .footer-bottom-grid {
    flex-direction: column;
    align-items: flex-start;
  }
}