/* index.css or App.css */

/* General styling */
body {
  font-family: 'Arial', sans-serif;
  background: linear-gradient(to bottom right, #ffffff, #f0f2f5); /* Light gradient background */
  color: #ffffff;
  margin: 0;
  padding: 0;
  position: relative;
  overflow-x: hidden;
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: radial-gradient(circle, transparent, rgba(255, 255, 255, 0.982));
  z-index: -1;
}

/* Title styling */
h1, h2, h3, h4, h5, h6 {
  color:#ff2222;
}

button:hover{
  background-color: #0049e7;
}

