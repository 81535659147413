.floating-button {
    position: fixed;
    bottom: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: white;
    font-size: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.3s;
    z-index: 2;
    font-size: 2rem;
}

.call-button {
    left: 20px;
    background-color: #2c64ad;
}

.call-button:hover {
    background-color: #34608a;
    transform: scale(1.1);
}

.whatsapp-button {
    right: 20px;
    background-color: #25D366;
}

.whatsapp-button:hover {
    background-color: #1DA851;
    transform: scale(1.1);
}
