@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');

/* General Styles */
body {
  font-family: 'Mukta', sans-serif;
  color: #2C3E50;
  background-color: #F8F8F8; /* Soft light gray for the body background */
}   
/* Our Work Section */
.our-work {
  padding: 0px 10px 6px 2px;
  background-color: #FFFFFF;
  text-align: center;
}

.our-work h2 {
  font-size: 2.5rem;
  color: #FF2222;
  margin-bottom: 20px;
}

.work-description {
  margin-bottom: 40px;
  font-size: 1.2rem;
  color: #7F8C8D;
  max-width: 800px;
  margin: 0 auto;
}

/* Grid Layout */
.work-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 6px;
}

.work-item {
  position: relative;
  overflow: hidden;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.work-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.work-item:hover img {
  transform: scale(1.1);
}

.work-item:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media (max-width: 768px) {
  .work-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}


/* About Us Section */
.about-us {
  padding: 3px 10px;
  background: linear-gradient(135deg, #FF5733 0%, #FF2222 100%);
  color: #fff;
  text-align: center;
  margin-top: 10px;
}

.about-us h2 {
  margin-bottom: 8px;
  font-size: 2.5rem;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  margin-top: 2px;
}
.about-us .container{
  padding: 0px 0 20px 10px;
}
.about-us h2:after {
  content: "";
  display: block;
  width: 50px;
  height: 3px;
  background-color: #fff;
  margin: 1px auto 0;
}

.about-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 40px;
  max-width: 1500px;
  margin: 0 auto;
}

.about-text {
  flex: 1;
  max-width: 900px;
  text-align: left;
  margin-bottom: 20px;
  animation: fadeInLeft 1s ease-in-out;
}

.about-text p {
  font-size: 1.2rem;
  line-height: 1.7;
  color: #F0F0F0;
  margin-bottom: 20px;
  letter-spacing: 0.5px;
  word-spacing: 1px;
}

.about-image {
  flex: 1;
  max-width: 450px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  background-color: #B00020;
  padding: 10px;
  margin-top: 10px;
}

.about-image img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .about-us {
    padding: 8px 10px;
    margin-top: 10px;
  }
  
  .about-us .container{
    padding: 0px 2px;
  }
  .about-content {
    flex-direction: column;
    align-items: center;
  }

  .about-text{
    width: 100%;
    margin-top: 2px;
  }
  .about-text p{
    margin-bottom: 2px;
    margin-top: 4px;
    text-align: left;
  }
  .about-us h2 {
    font-size: 2.2rem;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .about-us h3{
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

/* Call to Action (CTA) Section */
.call-to-action {
  padding: 12px 5px;
  background-color: #C70039;
  color: #fff;
  text-align: center;
  margin-top: 4px;  
}

.call-to-action h2 {
  margin-bottom: 30px;
  font-size: 2.5rem;
  color: #fff;
}

.call-to-action button {
  padding: 15px 30px;
  background-color: #fff;
  color: #C70039;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.2rem;
  transition: background-color 0.3s, transform 0.2s ease-in-out;
}

.call-to-action button:hover {
  background-color: #900C3F;
  transform: scale(1.05);
}

/* Blog Preview Section */
.blog-preview {
  padding: 60px 20px;
  background-color: #fff;
  text-align: center;
}

.blog-preview h2 {
  margin-bottom: 40px;
  font-size: 2.5rem;
  color: #C70039;
}

.blog-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.blog-post {
  position: relative;
  margin: 20px;
  overflow: hidden;
  width: 300px;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.blog-post:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.image-card {
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}

.image-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  display: block;
  transition: transform 0.3s ease-in-out;
}

.image-card:hover img {
  transform: scale(1.1);
}

.description-card {
  padding: 15px;
  box-sizing: border-box;
  background-color: #fff;
  text-align: left;
  border-radius: 0 0 10px 10px;
  position: relative;
  z-index: 1;
}

.description-card h3 {
  margin-bottom: 10px;
  font-size: 1.6rem;
  color: #C70039;
}

.description-card p {
  font-size: 1.2rem;
  color: #7F8C8D;
  margin: 0;
}

@media (max-width: 768px) {
  .blog-post {
    width: 100%;
    margin: 20px 0;
  }

  .image-card img {
    height: 150px;
  }
}