/* Testimonials Page */
.testimonials-page {
  padding: 60px 20px;
  background-color: #f8f8f8; /* Light background for contrast */
  text-align: center;
}

.testimonials-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 40px;
  color: #B22222; /* Deep red for a strong contrast */
  text-transform: uppercase;
  letter-spacing: 1px;
}

.testimonials-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.testimonial-card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 320px;
  text-align: left;
  position: relative;
}

.testimonial-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
}

.testimonial-image {
  height: 160px;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: relative;
}

.testimonial-image:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(178, 34, 34, 0.6), transparent); /* Red gradient overlay */
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.testimonial-content {
  padding: 20px;
}

.testimonial-feedback {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 15px;
  font-style: italic;
  position: relative;
  z-index: 2;
  border-left: 3px solid #B22222; /* Red border to emphasize text */
  padding-left: 15px; /* Space between text and border */
}

.testimonial-name {
  font-size: 1.3rem;
  font-weight: 600;
  color: #B22222; /* Red color for the name */
  margin-bottom: 5px;
}

.testimonial-role {
  font-size: 1rem;
  color: #999; /* Lighter gray for the role */
}

@media (max-width: 768px) {
  .testimonial-card {
    width: 100%; /* Full width on smaller screens */
    margin-bottom: 20px;
  }
}